import React from 'react';
import { graphql } from 'gatsby';
import {
  Actions,
  Block,
  Button,
  Container,
  Detail,
  Description,
  Half,
  Hero,
  Layout,
  Title,
  LayoutBlock,
  Row,
  Column,
  Section,
} from '@dragonchain-dev/dragon-kit';

import { Layout as PageLayout, SEO } from '../components/_index';

import Audit from '../assets/images/icons/audit.svg';
import GlobeDiscussion from '../assets/images/icons/globe-discussion.svg';
import Factor from '../assets/images/icons/factor.svg';
import Blockchain from '../assets/images/icons/blockchain-b.svg';
import GearForward from '../assets/images/icons/gear-forward.svg';
import Money from '../assets/images/icons/money.svg';

const Home = () => {
  const { IMAGES_URI } = process.env;

  return (
    <PageLayout>
      <SEO
        title="Dragonchain | Hybrid Blockchain Solutions and Services"
        description="An Open-source hybrid blockchain platform for the creation of decentralized applications. Write smart contracts, 
                     and securely integrate real world business data through RESTful APIs."
        image={`${IMAGES_URI}/banners/home.png`}
        cardType="summary_large_image"
      />
      <Hero
        type="code"
        backgroundImage="https://images.dragonchain.com/texture/clouds-blue@2x.jpg"
        video={{
          url: 'https://www.youtube.com/watch?v=sn_nKyE2Suw',
          autoPlay: true,
        }}
      >
        <Title color="white">The Most Secure and Flexible Blockchain Platform on Earth</Title>
        <Description color="blue-light">
          <p>
            Dragonchain is an enterprise and start up ready platform to build flexible and scalable blockchain
            applications.
          </p>
        </Description>
      </Hero>

      <LayoutBlock>
        <Container>
          <Row gap="small" span={8}>
            <Column>
              <Section
                caption="Dragonchain Product Suite"
                title="Solutions Ready For Your Business"
                footer={
                  <Button color="blue-light" hover="blue-dark" to="/business">
                    Explore Products
                  </Button>
                }
              >
                Dragonchain has business-ready applications and developer friendly integrations to better
                serve your business needs.
              </Section>
            </Column>
          </Row>
          <Row gap="small" columnGap="medium" span={4} tabletPortraitSpan={6}>
            <Column>
              <Detail title="Learning Management System" icon={<Blockchain />}>
              Lyceum provides traceable proof of course progress, completion, scoring, continuing education units, and certification.
              </Detail>
            </Column>
            <Column>
              <Detail title="Decentralized Identity" icon={<Factor />}>
                Factor is a GDPR and CCPA capable identity management platform to increase security, reduce
                risk of customer PII storage, and simplify authentication.
              </Detail>
            </Column>

            <Column>
              <Detail title="Anti-Fraud and Compliance" icon={<Audit />}>
                Fintech suite to prove regulatory compliance, data records, and provide selective transparency
                to internal employees, external third parties, regulators, and consumers.
              </Detail>
            </Column>
          </Row>
          <Row gap="large">
            <Column span={12}>
              <section
                className="block dark bg-blue-dark"
                style={{
                  margin: '0 var(--paddingRight) 0 var(--paddingLeft)',
                  width: '100%',
                  paddingTop: '50px',
                }}
              >
                <div className="inner">
                  <section className="full stack">
                    <div className="content center">
                      <Title tag="p" size="small" color="blue-light" weight="300">
                      Think of Dragonchain as a catalyst for dozens upon dozens of other disruptive blockchain platforms, making the tools easier and more accessible to developers and innovative minds.
                      </Title>
                      <Description>- The Huffington Post</Description>
                      <br />
                      <Button color="white" hover="blue" to="/business">
                        Learn more about our products
                      </Button>
                    </div>
                  </section>
                </div>
              </section>
            </Column>
          </Row>
          <Row gap="small" span={8}>
            <Column>
              <Section
                caption="Case Studies"
                title="Inspiration For Your Business"
                footer={
                  <Button color="blue-light" hover="blue-dark" to="/case-studies">
                    Explore some of our case studies
                  </Button>
                }
              >
                Dragonchain has a variety of solutions and applications with partners, solving real
                world problems for companies today.
              </Section>
            </Column>
          </Row>
          <Row gap="small" columnGap="medium" span={4} tabletPortraitSpan={6}>
            <Column>
              <Detail title="FinTech Compliance" icon={<Money />}>
                Providing transparency and anti-fraud solutions for regulated financial institutions.
              </Detail>
            </Column>
            <Column>
              <Detail title="Manufacturing" icon={<GearForward />}>
                Capturing coffee roast data from IoT enabled machines on blockchain to provide full
                transparency to consumers.
              </Detail>
            </Column>

            <Column>
              <Detail title="Covid-19" icon={<GlobeDiscussion />}>
                A privacy first testing and vaccination application enabling employees to go back to the office safely.
              </Detail>
            </Column>
          </Row>
          <Row gap="small" span={9} tabletPortraitSpan={12}>
            <Column>
              <Section
                title="Blockchain Platform"
                footer={
                  <Button type="link" to="/blockchain-platform" hover="blue-dark">
                    Explore features
                  </Button>
                }
              >
                Dragonchain provides a flexible, hybrid blockchain platform for businesses to rapidly deploy
                advanced applications, and integrate quantum-safe capabilities with existing systems.
              </Section>
            </Column>
          </Row>
          <Row gap="large" auto>
            <Column>
              <Row span={6} tabletPortraitSpan={6} phoneSpan={1}>
                <Column>
                  <Detail title="Quantum Safety">
                    A business can harness our latest Quantum Safe products to safeguard sensitive data well into the future - Encryption by Aqed
                  </Detail>
                </Column>
                <Column>
                  <Detail title="Proof">
                    Proof of every transaction on Dragonchain is secured to Bitcoin, Ethereum,
                    and other blockchain networks
                  </Detail>
                </Column>
                <Column>
                  <Detail title="High Performance">Instant processing of all transactions</Detail>
                </Column>
                <Column>
                  <Detail title="Smart Contracts">Code smart contracts in any language with no prior blockchain experience</Detail>
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
        <Layout dark background="blue-dark">
          <Block type="halves" divider>
            <Half
              center
              stack
              illustration={{ src: `${IMAGES_URI}/illustrations/call-to-action-consulting@2x.png` }}
            >
              <Title>For Business</Title>
              <p>
                Want to learn more on how to use blockchain to improve your business? See a few of our
                solutions to spark an idea.
              </p>
              <Button outline to="/business">
                See our Solutions
              </Button>
            </Half>
            <Half
              center
              stack
              illustration={{ src: `${IMAGES_URI}/illustrations/call-to-action-developers@2x.png` }}
            >
              <Title>For Developers</Title>
              <p>Want to write scalable blockchain applications in any language?</p>
              <Button outline to="/developers">
                Get Started
              </Button>
            </Half>
          </Block>
        </Layout>
      </LayoutBlock>
    </PageLayout>
  );
};

export default Home;

export const pageQuery = graphql`
  {
    files: allFile(filter: { relativePath: { regex: "/home/i" } }) {
      edges {
        node {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
          relativePath
        }
      }
    }
  }
`;
